import GlobeComponent from "./components/GlobeComponent";
import React from "react";
import "./App.css";

const handleClick = () => {
  const fileUrl = require("../src/assets2/GlobalArtha.pdf");
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", "GlobalArtha.pdf");
  document.body.appendChild(link);
  link.click();
};

export default function App() {
  return (
    <div className="appContainer">
      <div className="title">
        <p>GLOBAL ARTHA</p>
      </div>
      <div className="subTitle">
        <p>Business Beyond Borders</p>
      </div>

      <div className="names">
        <p>Trishank | Bhaskar</p>
      </div>
      <div className="email">
        <p>contact@globalartha.com</p>
      </div>

      <div className="intro">
        <p>
          International B2B marketplace designed to bring together businesses
          from around the globe.
        </p>
      </div>
      <div className="introTwo">
        <p>Global Business Marketplace</p>
      </div>
      <div className="comingSoon">
        <p>Coming Soon</p>
      </div>
      <div className="globe">
        <GlobeComponent />
      </div>

      <button onClick={handleClick} className="downloadButton">
        Download Presentation
      </button>
    </div>
  );
}
